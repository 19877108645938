body {
  background-image: linear-gradient(120deg,
      #c8e8ff76 0%,
      #d1bdf071 30%,
      #efc8dc9a 60%,
      #fceeff21 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: 0 0;



}

.container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-container {
  margin-left: 250px;
  margin-right: 250px;
  margin-top: 10px;
  margin-bottom: 0;

}

.custom-logo-link {
  text-decoration: none;
}

a .brand {
  text-decoration: none;
  font-size: 30px;
  color: black;
  font-weight: 700;

}

.connectStatus {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  margin-top: 25px;
  background-color: black;
  border-radius: 30px;
  padding: 10px;
}

.body_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;


}

.fa-wallet {
  cursor: pointer;
}

.nav_connnect {
  text-decoration: none;
  color: black;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: -0.006em;
  margin-bottom: 10px;
  border: none;
  border-radius: 20px;
  padding: 5px;
  cursor: pointer;
}

.presentation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 185px;
  margin-bottom: 130px;
  /* border: 1px solid red; */


}

.p1_spacer {
  width: 5%;
}

.p1_highlight {
  font-size: 11px;
  color: black;
  font-weight: 600;
  letter-spacing: -0.006em;
  margin-bottom: 10px;

}

.p1_title {
  width: 50%;
  font-size: 75px;
  letter-spacing: -0.011em;
  color: black;
  font-weight: bold;
  margin-bottom: 20px;
}

.p1_subtitle {
  font-size: 16px;
  color: black;
  font-weight: 300;
  margin-bottom: 40px;
}

.p1_btn {
  background-color: rgb(250, 79, 9);
  color: white;
  border-radius: 30px;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;

}

.p1_img {
  width: 50%;
}

.action_title {
  text-align: center;
  margin-top: 150px;
  font-size: 45px;
  font-weight: 600;
  color: black;
}

.action_subtitle {
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  color: black;
  margin-bottom: 40px;
  margin-top: 10px;
}

.cta-container {
  width: 28vw;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
}

.cta-container {
  text-decoration: none;
}


.cta_btn {
  background-color: black;
  color: white;
  border-radius: 30px;
  padding: 10px 20px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}

.guide {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
  width: 90%;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 15px;
  padding: 20px;
}

.guide_flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.guide_flex img {
  width: 120px;
  margin-right: 5%;
}

.guide_flex h2 {
  font-size: 20px;
  font-weight: 600;
  color: black;
}

.guide_flex p {
  font-size: 14px;
  font-weight: 300;
  color: black;
  width: 70%;
}

.site-footer {
  background-color: #333;
  height: auto;
  width: 100%;
  color: #fff;
  padding: 50px;
  font-size: 14px;
}

.footer-content {
  text-align: left;
  max-width: 400px;
  margin-bottom: 0;

}

.footer-content .description {
  margin: 0;
  padding-bottom: 40px;


}

.copyright {
  text-align: center;
  bottom: 10px;
  width: 100%;
  margin: 0;
  font-size: 12px;
  color: white;
}


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.action_title {
  text-align: center;
  margin-top: 50px;
  font-size: 45px;
  font-weight: 600;
  color: black;
}

.wallets_container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 50px;

}

.wallet-cta-container {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(97, 96, 96, 0.2), 0 2px 50px 0 rgba(109, 108, 108, 0.19);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
}

.wallet-img-div {
  border: hidden;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 0px;
  background-size: cover;
}

.wallet-name {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

@media screen and (max-width: 1024px) {

  .main-container {
    margin-left: 15px;
    margin-right: 15px;

  }

  a .brand {
    font-size: 18px;
  }

  .presentation {
    justify-content: space-between;
    margin-bottom: 120px;

  }

  .p1_highlight {
    font-size: 11px;
    color: black;
    font-weight: 600;
    letter-spacing: -0.006em;
    margin-bottom: 10px;

  }

  .p1_title {
    width: 90%;
  }

  .p1_subtitle {
    font-weight: 500;
  }

  .p1_img {
    width: 60%;
  }

  .action_title {
    font-size: 30px;
  }

  .action_subtitle {
    font-size: 16px;
    font-weight: 500;
  }

  .cta-container {
    width: 50vw;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }


  .cta_btn {
    font-size: medium;
  }

  .guide {
    width: 100%;
  }


  .guide_flex img {
    width: 30%;
    margin-right: 1%;
    /* background-color: red; */
  }

  .guide_flex #jo {
    width: 20%;
  }

  .guide_flex h2 {
    font-size: 16px;
  }

  .guide_flex p {
    word-wrap: break-word;
    width: 100%;
    /* background-color: red; */
  }

  .guide_text_con {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {


  .p1_title {
    width: 90%;
    font-size: xx-large;
  }

  .p1_img {
    width: 50%;
  }

  .action_title {
    font-size: x-large;
  }

  .action_subtitle {
    font-size: 12px;
  }

  .cta-container {
    width: 70vw;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .wallets_container {
    grid-template-columns: 1fr 1fr 1fr;

  }

  .guide_flex h2 {
    font-size: 16px;
  }

  .guide_flex p {
    font-size: small;
  }

  .guide_text_con {
    width: 90%;
  }

  #dx {
    width: 40%;
  }

  .site-footer {
    height: auto;
  }
}

@media screen and (max-width: 425px) {

  .presentation {
    flex-direction: column-reverse;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 120px;
  }

  .p1_title {
    width: 100%;
    font-size: xx-large;
    text-align: center;
  }

  .p1_img {
    width: 90%;
  }

  .p1_highlight {
    margin-top: 20px;
    text-align: center;
  }

  .p1_subtitle {
    font-size: 13px;
    text-align: center;
  }

  .p1_text_con {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .action_title {
    font-size: x-large;
    text-align: center;
    margin-top: 50px;
  }

  .action_subtitle {
    font-size: 11px;
    text-align: center;
    width: 100%;
  }

  .cta-container {
    width: 90vw;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));

  }

  .cta-container a {
    text-decoration: none;
  }

  .cta_btn {
    font-size: 11px;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .guide {
    flex-direction: column;
    height: auto;
    width: 90vw;
  }

  .guide_flex {
    margin-bottom: 20px;
  }

  .guide_flex h2 {
    font-size: 16px;
  }

  .guide_flex p {
    font-size: small;
  }

  .guide_text_con {
    width: 90%;
  }

  .guide_flex #jo {
    width: 30%;
  }

  #dx {
    width: 90%;
  }

  .action_title {
    font-size: 30px;
  }

  .wallets_container {
    grid-template-columns: 1fr 1fr;
    width: 90%;

  }

  .wallet-img-div {
    border: hidden;
    width: 40px;
    height: 40px;
  }

  .wallet-name {
    margin-left: 7px;
    font-size: 13px;
  }
}

@media screen and (max-width: 320px) {

  .main-container {
    margin-left: 15px;
    margin-right: 15px;

  }

  a .brand {
    font-size: 18px;

  }

  .presentation {
    margin-bottom: 100px;
  }

  .p1_highlight {
    font-size: 10px;

  }

  .p1_title {
    width: 60%;
  }

  .p1_subtitle {
    font-size: 13px;
    font-weight: 500;
  }

  .p1_img {
    width: 80%;
  }

  .action_subtitle {
    font-weight: 500;
  }

  .cta-container {
    width: 90vw;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

  }

  .footer-content {
    font-size: small;
  }
}